// Header.js

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './css/main.css'; // Make sure to create a Header.css file with your styles
import logo from './images/logo_white.png';
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false
        }
        this.toggleMenu = this.toggleMenu.bind(this);
    }
    componentDidMount() {
		window.scroll(0, 0);
	}  

    toggleMenu() {
        this.setState({isMenuOpen: !this.state.isMenuOpen});
    }
  render() {
    return (
        <header className="header">
                  <div className="logo">
          <img src={logo} alt="Logo" className="logo-header"/>
        </div>
        <div className="main-nav">
        <nav className={`nav ${this.state.isMenuOpen ? 'open' : ''}`}>
        <Link className="nav-link" to="/">Home</Link>
          <Link className="nav-link" to="/About">About</Link>
          <Link className="nav-link" to="/Workshops">Workshops</Link>
          <Link className="nav-link" to="/Blog">Blog</Link>
          <Link className="nav-link" to="/Research">Research</Link>
          <Link className="nav-link" to="/Contact">Contact</Link>
          <Link className="nav-link" to="/Register">Register</Link>
        </nav>
        <div className="hamburger" onClick={this.toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        </div>
      </header>
    
    );
  }
}

export default Header;
