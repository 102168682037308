import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { Component } from 'react';
import HomeWrapper from './HomeWrapper';
import About from './About';
import Workshops from './Workshops';
import Blog from './Blog';
import Research from './Research';
import Contact from './Contact';
import Register from './Register';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import './css/main.css';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	//<Route exact path='/preview' render={(props) => <Home {...props} preview={true} />} />
	render() {
		return (
<div className="App">
				<BrowserRouter>
					<Routes>
          <Route path="/" element={<HomeWrapper />} />
          <Route path="/Home" element={<HomeWrapper />} />
          <Route path="/About" element={<About />} />
          <Route path="/Workshops" element={<Workshops />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Research" element={<Research />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsOfUse" element={<TermsOfUse />} />
          </Routes>
        </BrowserRouter>
      </div>
	
    )
    		/*<div className="App">
				<BrowserRouter>
					<Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Workshops" element={<Workshops />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Research" element={<Research />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Register" element={<Register />} />
          </Routes>
        </BrowserRouter>
      </div>*/
    }
  }

export default App;
