// HomeWrapper.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Home from './Home'; // Import the class component

function HomeWrapper(props) {
  const navigate = useNavigate();
  
  // Pass navigate as a prop to the Home class component
  return <Home {...props} navigate={navigate} />;
}

export default HomeWrapper;
