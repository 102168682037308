import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
class About extends Component {
    render() {
        return(
            <div>
                <Header />
                <div className="home-section">
        
        <div className="column">
          <div className="extra-padding extra-bottom-padding">
            <h1 className="column-main-header"><u className="underline">A Little</u> More About Elliot</h1>
            <p className="paragraph-text">
            Elliot’s powerful combination of expertise in research and technology has enabled him to be positively disruptive in the mental health industry. Elliot spent over 20 years studying, researching, and developing in the cognitive sciences, with 15 years of formalized research studies. This work culminated in the identification of three scientific theories that empowered a formalized therapeutic approach, <a href="https://cognitiveresponsetherapy.info">Cognitive Response Therapy</a>, that has been adopted by licensed practitioners. He used the intellectual property to create a formalized mental health program, <a href="https://mycoreinsights.com/mft">Mental Fitness Training</a> and was the first to combine the Agile/Scrum technology framework with a mental health program at <a href="https://mycoreinsights.com">My Core Insights</a> to deliver the training. 
            </p>          
            </div>
        </div>
        <div className="column">
        <iframe
  title="Introduction Video"
  className="responsive-video"
  src="https://player.vimeo.com/video/884585227"
  frameBorder="0"
  allow="autoplay; fullscreen"
  allowFullScreen
  mozallowfullscreen="true"
  webkitallowfullscreen="true">
</iframe>        </div>
        </div>
                <Footer />
            </div>
        )
    }

}

export default About;