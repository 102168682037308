// Blog.js

import React, { Component } from 'react';
import './css/Blog.css'; // Ensure the path to your CSS file is correct
import Header from './Header';
import Footer from './Footer';

class Blog extends Component {
    componentDidMount() {
		window.scroll(0, 0);
	}
  render() {
    /*      <div className="latest-blogs">
          <p className="smaller-header-center">Latest Video</p>
          <div className="latest-blog-post">
            <a href="#">Latest Video 1</a>
          </div>
        </div>
        */
    return (
        <div>

         <Header />
         <div className="blog-header">
                <p className="blog-header-text">Elliot's Blog</p>
         </div>
      <div className="blog-container">

        <div className="main-content">
          <div className="column-container">
            <iframe
  title="Introduction Video"
  className="responsive-video"
  src="https://player.vimeo.com/video/477276768"
  frameBorder="0"
  allow="autoplay; fullscreen"
  allowFullScreen
  mozallowfullscreen="true"
  webkitallowfullscreen="true">
</iframe> 
            <p className="smaller-header-center">Key Questions to Empower You!</p>
          </div>
          <div className="column-container">
            <iframe
  title="Introduction Video"
  className="responsive-video"
  src="https://player.vimeo.com/video/477690623"
  frameBorder="0"
  allow="autoplay; fullscreen"
  allowFullScreen
  mozallowfullscreen="true"
  webkitallowfullscreen="true">
</iframe> 
            <p className="smaller-header-center">Honor your Future Self!</p>
          </div>
          <div className="column-container">
            <iframe
  title="Introduction Video"
  className="responsive-video"
  src="https://player.vimeo.com/video/476484684"
  frameBorder="0"
  allow="autoplay; fullscreen"
  allowFullScreen
  mozallowfullscreen="true"
  webkitallowfullscreen="true">
</iframe> 
            <p className="smaller-header-center">The 6th Love Language</p>
          </div>
          <div className="column-container">
            <iframe
  title="Introduction Video"
  className="responsive-video"
  src="https://player.vimeo.com/video/473964116"
  frameBorder="0"
  allow="autoplay; fullscreen"
  allowFullScreen
  mozallowfullscreen="true"
  webkitallowfullscreen="true">
</iframe> 
            <p className="smaller-header-center">Closer or Further Away</p>
          </div>
          <div className="column-container">
            <iframe
  title="Introduction Video"
  className="responsive-video"
  src="https://player.vimeo.com/video/473954932"
  frameBorder="0"
  allow="autoplay; fullscreen"
  allowFullScreen
  mozallowfullscreen="true"
  webkitallowfullscreen="true">
</iframe> 
            <p className="smaller-header-center">Get the Little Things Right</p>
          </div>
          <div className="column-container">
            <iframe
  title="Introduction Video"
  className="responsive-video"
  src="https://player.vimeo.com/video/462850861"
  frameBorder="0"
  allow="autoplay; fullscreen"
  allowFullScreen
  mozallowfullscreen="true"
  webkitallowfullscreen="true">
</iframe> 
            <p className="smaller-header-center">Waiting for a Crisis</p>
          </div>
          <div className="column-container">
            <iframe
  title="Introduction Video"
  className="responsive-video"
  src="https://player.vimeo.com/video/462850573"
  frameBorder="0"
  allow="autoplay; fullscreen"
  allowFullScreen
  mozallowfullscreen="true"
  webkitallowfullscreen="true">
</iframe> 
            <p className="smaller-header-center">Direct Your Moment!</p>
          </div>
          <div className="column-container">
            <iframe
  title="Introduction Video"
  className="responsive-video"
  src="https://player.vimeo.com/video/462850399"
  frameBorder="0"
  allow="autoplay; fullscreen"
  allowFullScreen
  mozallowfullscreen="true"
  webkitallowfullscreen="true">
</iframe> 
            <p className="smaller-header-center">Resolving Conflict</p>
          </div>
        </div>

        </div>
        <Footer />
      </div>
    );
  }
}

export default Blog;
