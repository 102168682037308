import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
class PrivacyPolicy extends Component {
    componentDidMount() {
		window.scroll(0, 0);
	}
    render() {
        return(
            <div>
                <Header />
        <div className="research-section">
            <p className="research-header">
            ElliotBarnett.com Privacy Policy
            </p>
            <p className="smaller-header">Introduction</p>
            <p className="research-content">
            Here at ElliotBarnett.com, we know your personal information and its protection is crucially important to you. We take this topic very seriously. The details of how we approach this topic and what we do about this topic is articulated below. By using the ElliotBarnett.com website (herein referred to as “The Site”), you are agreeing to these terms and practices. The Site provides information for entertainment purposes.
            </p>
            <p className="smaller-header">What Personal Information About Customers Does ElliotBarnett.com Collect?
            </p>
            <p className="research-content">
            All the information we collect at The Site is provided by you, the user of The Site with a few exceptions such as but not limited to: your last login date, pages of The Site you have visited, support questions, feedback, and number of visits. This information is used to improve our ability to provide content, answer questions, and help guide you in using The Services.
            </p>
            <p className="smaller-header">What do you do with my information?
            </p>
            <p className="research-content">
            Your email is used to communicate with you for the purposes of providing important information or promotions for The Site and is used as your login username, allowing you access to the membership areas of The Site. Your name is used for identification with The Site only.

The information you provide in the form of feedback and questions are used to provide answers to you to help improve your experience at The Site.

The information you provide in The Training is for your own usage only. The algorithms of The Training manipulate, collate, and connect your provided information to other information you provide to provide additional information to you. All of this is for you and only your information.
            </p>
            <p className="smaller-header">Do you share my information?
            </p>
            <p className="research-content">
            You have control over what information is shared to other members and users of The Site and The Training. That control is provided in the Privacy Settings area of your Profile at The Site. No other visitors or members of The Site can see any information you provide to The Site without you explicitly allowing it.
You can remove some or all of your information you have provided to The Site at any time. You have the option of removing your account which fully deletes all the personal information you have provided. You can request a removal of your account by emailing info@mycoreinsights.com.
Access to the personal information you provide is obfuscated to employees of ElliotBarnett.com with the exception of your name and email which is necessary to communicate to you appropriately. Your provided information is yours alone and used for your own growth while going through The Training.
            </p>
            <p className="smaller-header">How secure is my information?
            </p>
            <p className="research-content">
            ElliotBarnett.com is committed to protecting your information. Our approach:
            aligns with industry recognized frameworks
            includes security safeguards reasonably designed to protect the confidentiality, integrity, availability, and resilience of our Users' data
            is appropriate to the nature, size, and complexity of the ElliotBarnett.com’s business operations
            includes incident response and data breach notification processes
            complies with applicable information security-related laws and regulations in the geographic regions where ElliotBarnett.com does business.
            In the event of a data breach that affects your personal Information, we will act promptly to mitigate the impact of a breach and notify any affected Users without undue delay. Transmission of data related to The Site is encrypted using SSH, HTTPS (TLS). The Site resides within the Amazon Web Services (AWS) environment which provides high standards of security detailed on the AWS website under the security area
            </p>
            <p className="smaller-header">Cookies
            </p>
            <p className="research-content">
            We use Cookies to setup your user session and allow you to gain access to the membership areas of The Site only. These Cookies are limited to session information only and don’t contain any personal information about you. We recognize your browser and create a session ID to provide you with a membership experience for The Site.
            </p>
            <p className="smaller-header">EU-US and Swiss-US Privacy Shield
            </p>
            <p className="research-content">
            ElliotBarnett.com participates in the EU-US and Swiss-US Privacy Shield frameworks regarding the collection, use, and retention of personal information from European Union member countries, the United Kingdom and Switzerland. We have certified with the Department of Commerce that we adhere to the Privacy Shield Principles. To learn more about the Privacy Shield Principles, visit here
If you have any inquiries or complaints about our handling of your personal data under Privacy Shield, or about our privacy practices generally, please contact us at: info@mycoreinsights.com. We will respond to your inquiry promptly. If we are unable to satisfactorily resolve any complaint relating to the Privacy Shield, or if we fail to acknowledge your complaint in a timely fashion, you can submit your complaint to TRUSTe, which provides an independent third-party dispute resolution service based in the United States. TRUSTe has committed to respond to complaints and to provide appropriate recourse at no cost to you. To learn more about TRUSTe's dispute resolution services or to refer a complaint to TRUSTe, visit here. If neither ElliotBarnett.com nor TRUSTe resolves your complaint, you may pursue binding arbitration through the Privacy Shield Panel. To learn more about the Privacy Shield Panel, visit here.
You can review our Privacy Shield registration here. ElliotBarnett.com is subject to the investigatory and enforcement powers of the Federal Trade Commission (FTC). We may be required to disclose personal information that we handle under the Privacy Shield in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.
            </p>
            <p className="smaller-header">California Consumer Privacy Act Disclosures
            </p>
            <p className="research-content">
            These additional disclosures are required by the California Consumer Privacy Act:
Categories of personal information collected. The business of ElliotBarnett.com started after the inception of the California Consumer Privacy Act so no personal information was collected previously to this act.
Categories of personal information disclosed for a business purpose. The business of ElliotBarnett.com started after the inception of the California Consumer Privacy Act so no personal information was disclosed previously to this act.
Right to Request Access to or Deletion of Personal Information. You may have the right under the California Consumer Privacy Act to request information about the collection of your personal information by ElliotBarnett.com, or access to or deletion of your personal information. If you wish to do any of these things, please visit your profile at The Site of contact us at info@mycoreinsights.com. Depending on your data choices, certain services may be limited or unavailable.
No sale of personal information. In the twelve months prior to the effective date of this Disclosure, ElliotBarnett.com has not sold any personal information of consumers, as those terms are defined under the California Consumer Privacy Act.
No Discrimination. ElliotBarnett.com will not discriminate against any consumer for exercising their rights under the California Consumer Privacy Act.            </p>
        </div>


                <Footer />
            </div>
        )
    }

}

export default PrivacyPolicy;