// Contact.js

import React from 'react';
import Header from './Header'; // Header component
import Footer from './Footer'; // Footer component
import './css/Contact.css'; // CSS file for styling
import { APIHost } from './GlobalVariables';
import axios from 'axios';

const NameEmpty = () => (
	<p><font color="red">Please make sure to provide your name.</font></p>
);

const EmailEmpty = () => (
	<p><font color="red">Please make sure to provide a valid email address.</font></p>
);

const MessageEmpty = () => (
	<p><font color="red">Please make sure to provide some message content.</font></p>
);

const MessageFailed = () => (
	<p><font color="red">Your message failed to send. Please try again.</font></p>
);

const MessageSent = () => (
	<p><font color="blue">Thanks so much for your message! We will get back to you soon.</font></p>
);

class Contact extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			email: '',
			message: '',
			name: '',
			nameEmpty: false,
			emailEmpty: false,
			messageEmpty: false,
			messageSent: false,
			messageFailed: false
		}
		this.SendMessage = this.SendMessage.bind(this);
		this.sendMessageCallBack = this.sendMessageCallBack.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
	}

    onChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

    sendMessageCallBack(response, errorMessage) {
		if (response) {
			this.setState({
				messageFailed: false,
				nameEmpty: false,
				emailEmpty: false,
				messageEmpty: false,
				messageSent: true
			});
		} else {
			if (errorMessage === "name empty") {
				this.setState({ nameEmpty: true });
			} else if (errorMessage === "email empty") {
				this.setState({ emailEmpty: true });
			} else if (errorMessage === "message empty") {
				this.setState({ messageEmpty: true });
			} else {
				this.setState({ messageFailed: true });
			}
		}

	}

	SendMessage(sendMessageCallBack) {
		var passed = true;
		if (this.state.name === '') {
			passed = false;
			this.sendMessageCallBack(false, "name empty");
		}
		if (this.state.email === '') {
			passed = false;
			this.sendMessageCallBack(false, "email empty");
		}
		if (this.state.message === '') {
			passed = false;
			this.sendMessageCallBack(false, "message empty");
		}
		if (passed) {
			axios.post(APIHost + '/api/sendMessage', {
				email: this.state.email,
				message: this.state.message,
				name: this.state.name,
				withCredentials: true
			}).then(res => {
				this.sendMessageCallBack(true);
			}).catch(error => {
				console.log("error is " + error.response.data.errorMessage);
				this.sendMessageCallBack(false, error.response.data.errorMessage);
			});
		}
	}

	onSubmit(event) {
		event.preventDefault();
		this.setState({
			messageFailed: false,
			nameEmpty: false,
			emailEmpty: false,
			messageEmpty: false,
			messageFailed: false
		});
		this.SendMessage(this.sendMessageCallBack);
	}

  render() {
    return (
      <div className="contact-page">
        <Header />
        <main className="contact-content">
          <h1 className="research-header">Get in Touch</h1>
          <form className="contact-form" onSubmit={this.onSubmit}>
            <div className="form-group">
              <i className="fa fa-user icon"></i>
              <input type="text" name="name" onChange={this.onChange} placeholder="Name" />
            </div>
            <div className="form-group">
              <i className="fa fa-envelope icon"></i>
              <input type="email" name="email" onChange={this.onChange} placeholder="Email" />
            </div>
            <div className="form-group">
              <i className="fa fa-pencil icon"></i>
              <textarea placeholder="Message" name="message" onChange={this.onChange} rows="4"></textarea>
            </div>
            <button className="register-button" disable={this.state.messageSent ? true : false} type="submit">Submit</button>
          </form>
          {this.state.nameEmpty && <NameEmpty />}
							{this.state.emailEmpty && <EmailEmpty />}
							{this.state.messageFailed && <MessageFailed />}
							{this.state.messageEmpty && <MessageEmpty />}
							{this.state.messageSent && <MessageSent />}
        </main>
        <Footer />
      </div>
    );
  }
}

export default Contact;
