// Footer.js

import React, { Component } from 'react';
import './App.css';
import logo from './images/logo_white.png';
import { Link } from 'react-router-dom';

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-col">
          <div className="logo">
            <img src={logo} alt="Footer Logo" className="logo"/>
          </div>
          <p>© 2024 ElliotBarnett.com, All rights reserved.</p>
        </div>

        <div className="footer-col">
            <div className="footer-column">
                
                <div className="footer-col">
                    <div className="footer-row">
        <Link className="footer-link" to="/PrivacyPolicy">Privacy Policy</Link>
        <Link className="footer-link" to="/TermsOfUse">Terms of Use</Link>
        </div>
        <div className="footer-row">
          <Link className="footer-link" to="/">Home</Link>
          <Link className="footer-link" to="/About">About</Link>
          <Link className="footer-link" to="/Workshops">Workshops</Link>
          <Link className="footer-link" to="/Blog">Blog</Link>
          <Link className="footer-link" to="/Research">Research</Link>
          <Link className="footer-link" to="/Contact">Contact</Link>
          <Link className="footer-link" to="/Register">Register</Link>
          </div>
        </div>
        </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
