// Home.js

import React, { Component } from 'react';
import elliot from './images/speaking_nobackground.png';
import book from './images/book_cover.jpg';
import seminar from './images/seminar.jpg';
import OM from './images/OurMindsetLogo.jpg';
import MCI from './images/My-Core-Insights-Cropped.png';
import CRT from './images/crt-high-resolution-logo.png';
import './App.css'; // Make sure the path to your CSS file is correct
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';

class Home extends Component {
    constructor(props) {
        super(props);
        this.DirectToRegister = this.DirectToRegister.bind(this);
    }
    componentDidMount() {
		window.scroll(0, 0);
	}

    DirectToRegister(event) {
		event.preventDefault();
        //this.props.history.push({ pathname: "/Register"});
        this.props.navigate("Register");
    }

  render() {
    return (
        <div>
        <Header history={this.props.history} />
      <div className="home-section">
      <div className="community-section">
        <p className="community-text">
Join the wait list for the new mental health courses for individuals and certification program for coaches launching early 2025!          <button className="register-button" onClick={this.DirectToRegister}>Register</button>
        </p>
      </div>
        <div className="column">
          <div className="extra-padding">
            <h2 className="column-header">Welcome to</h2>
  
            <h1 className="column-main-header"><u className="underline">The Home</u> of Elliot Barnett</h1>
            <p className="quote">
              Empowering your Optimal Life!
                        </p>
            <p className="paragraph-text">
 
            Elliot Barnett is a cognitive science researcher, an author, an inspirational public speaker, and a technology innovator dedicated to revolutionizing the mental health industry. As someone deeply concerned with the limitations of traditional mental health practices, Elliot is committed to making mental health more affordable, accessible, and effective. With over 20 years in cognitive neuroscience and 25 years in technology innovation, he introduces new therapeutic approaches and technological innovations that empower individuals to maximize their mental well-being and empower those that help others. Elliot's approach challenges outdated models by focusing on the foundational role of the unconscious mind and the importance of metabolic health in mental wellness, paving the way for a reformed and effective mental health industry."

            </p>
            <p className="quote">
              "You control only these two things: how you prepare to respond and how you actually respond. Get this right and you will live an Optimal Life." 
              <div className="justify-signature">
              - Elliot Barnett
              </div>
            </p>
          </div>
        </div>
        {/* Placeholder for the second column if needed */}
        <div className="column">
        <img src={elliot} width="400" height="500" />
        </div>
        </div>

      
      <div className="home-section2">
        
        <div className="column">
          <div className="extra-padding">
            <h1 className="column-main-header"><u className="underline">The Myth</u> of Living Better</h1>
            <h2 className="column-header">Here's what they don't tell you about living an Optimal Life!</h2>
            <p className="column-text">
            Get the 5-star rated book from Elliot now on <a href="https://www.amazon.com/dp/B09MYTNTBM">Amazon</a>. The Myth of Living Better is a thoughtful tale of self-discovery and self-mastery. It is a journey to discover what true success means, what living a life of Purposeful Meaning is, and ultimately what living an Optimal Life truly means. You will feel the struggles and triumphs the main character Jeremy faces as if they are your own, and as you follow Jeremy’s journey, you will discover what They don’t tell you about how to live an Optimal Life.            </p>
          </div>
        </div>
        <div className="column">
        <img src={book} width="400" height="400" />
        </div>
        </div>
        <div className="community-section">
  <h1 className="feature-title"><u className="underline">Empower</u> your practice with Cognitive Response Therapy!</h1>
  <div className="feature-list">
    <div className="feature-column">
      <p><i className="checkmark">✓</i> Easily integrated into existing practices</p>
      <p><i className="checkmark">✓</i> Addresses foundational challenges</p>
    </div>
    <div className="feature-column">
      <p><i className="checkmark">✓</i> Existing programs are available</p>
      <p><i className="checkmark">✓</i> Evidence based and research driven</p>
    </div>
  </div>
  <p className="feature-description">
  Join many other mental health practitioners and coaches that have learned how to incorporate <a href="https://cognitiveresponsetherapy.info">Cognitive Response Therapy</a> into their existing practice. <a href="https://cognitiveresponsetherapy.info/Training">Training</a> workshops are offered monthly with self-directed training coming soon!
  </p>
</div>
<section className="testimonial-section">
          <div className="container">
            <h2 className="feature-title">Testimonials</h2>
            <div className="testimonial-grid">
              <div className="testimonial">
                <p className="testimonial-content">
                "Elliot is such an inspirational speaker and his knowledge of cognitive science and the subconscious really came across during the workshops. Elliot kept us engaged through his thought-provoking content and the interactive design of his platform that allowed everyone to complete the inner work moving us towards what he describes as Optimal Mental Fitness. At the end of the workshops, each participant had completed a self-assessment and developed tangible action steps to achieve their Optimal Life – personally & professionally."</p>               <p className="testimonial-author">Ulrike Berzau
MM MHS PT FACHE</p>
              </div>
              <div className="testimonial">
                <p className="testimonial-content">
                "Elliot has taken his innovative approach to business and applied to mental wellness to make it affordable, accessible and most importantly de-stigmatized. The agile platform he created to guide people on their mental wellness journey meets them where they are as human beings, without judgement or labels, and fits neatly into a corporate wellness program. His extraordinary understanding of the subconscious' role in behavior and how to strengthen the "mental core" underpins the effectiveness of this universally appealing modality. "</p>            <p className="testimonial-author">-- Maura Barclay
CCMP-NFP</p>
              </div>
              <div className="testimonial">
                <p className="testimonial-content">
                "My Core Insights IS the missing puzzle piece. The part that doesn't get learned in therapy. How we respond to the thoughts that we have instead of trying to control what we think about. How we speak to ourselves in any one given situation. My Core Insights and the incredible program Elliot offers is a process that puts into perspective a model that not only is not taught, but it also simply doesn't exist anywhere else. "</p>               <p className="testimonial-author">- Katherine Layman</p>
              </div>              
            </div>
          </div>
        </section>
        <div className="work-section">
        <div className="container-work">
            <h2 className="feature-title"><u className="underline">Ways to</u> Work With Elliot</h2>
            <div className="testimonial-grid">
              <div className="column-container">
                <p className="work-content">
                    Workshops
               </p>
               <div>
                <img src={seminar} width="350" height="300" />
            </div>
            <p className="work-content">
            Elliot runs powerful and actionable workshops for companies and organizations. Check the <Link to="/Workshops">Workshops</Link> page for details.
            </p>
              </div>
              <div className="column-container">
              <p className="work-content">
                    Cognitive Response Therapy
               </p>
               <div>
                <img src={CRT} width="350" height="300" />
            </div>
            <p className="work-content">
            The official site for <a href="https://cognitiveresponsetherapy.info">Cognitive Response Therapy</a>. Find training, information, and more            </p>
              </div>
              <div className="column-container">
              <p className="work-content">
                    My Core Insights
               </p>
               <div>
                <img src={MCI} width="350" height="300" />
            </div>
            <p className="work-content">
              A revolutionary <a href="https://mycoreinsights.com">technology platform</a> delivering an equally revolutionary mental wellness program, <a href="https://mycoreinsights.com/MFT">Mental Fitness Training</a>.
            </p>
              </div>
                           
            </div>
          </div>
        </div>
      <Footer history={this.props.history} />
      </div>
    );
  }
}

export default Home;
