// Contact.js

import React from 'react';
import Header from './Header'; // Header component
import Footer from './Footer'; // Footer component
import './css/Contact.css'; // CSS file for styling
import './css/phonenumber.css';
import PhoneInput from 'react-phone-number-input';
import { APIHost } from './GlobalVariables';
import axios from 'axios';

const NameEmpty = () => (
	<p><font color="red">Please make sure to provide your name.</font></p>
);

const EmailEmpty = () => (
	<p><font color="red">Please make sure to provide a valid email address.</font></p>
);

const RegisterFailed = () => (
	<p><font color="red">Something went wrong. Please refresh and try again. If the problem persists, please use the Contact form to let us know.</font></p>
);

const AccountExists = () => (
	<p><font color="red">An account exists with that email.</font></p>
);

const RegisterSuccess = () => (
	<p><font color="blue">Thanks so much for your registering! You have been added to the wait list for the new courses and certification programs launching early in 2025.</font></p>
);

class Register extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			email: '',
			message: '',
			name: '',
			nameEmpty: false,
			emailEmpty: false,
			messageEmpty: false,
			messageSent: false,
			messageFailed: false,
            phonenumber: ""
		}
		this.Register = this.Register.bind(this);
		this.RegisterCallback = this.RegisterCallback.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
	}

    onChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

    RegisterCallback(response, errorMessage) {
		if (response) {
			this.setState({
				registerFailed: false,
				nameEmpty: false,
				emailEmpty: false,
                accountExists: false,
				registerSuccess: true
			});
		} else {
			if (errorMessage === "name empty") {
				this.setState({ nameEmpty: true });
			} else if (errorMessage === "email empty") {
				this.setState({ emailEmpty: true });
			} else if (errorMessage === "Account exists with that email") {
                this.setState({accountExists: true});
            
            } else {
				this.setState({ registerFailed: true });
			}
		}

	}

	Register() {
		var passed = true;
		if (this.state.name === '') {
			passed = false;
			this.RegisterCallback(false, "name empty");
		}
		if (this.state.email === '') {
			passed = false;
			this.RegisterCallback(false, "email empty");
		}
		if (passed) {
			axios.post(APIHost + '/api/register', {
				email: this.state.email,
				phonenumber: this.state.phonenumber,
				name: this.state.name,
				withCredentials: true
			}).then(res => {
				this.RegisterCallback(true);
			}).catch(error => {
				console.log("error is " + error.response.data.errorMessage);
				this.RegisterCallback(false, error.response.data.errorMessage);
			});
		}
	}

	onSubmit(event) {
		event.preventDefault();
		this.setState({
			nameEmpty: false,
			emailEmpty: false,
			registerFailed: false
		});
		this.Register();
	}

  render() {
    return (
      <div className="contact-page">
        <Header />
        <main className="contact-content">
          <h1 className="research-header">You will get notified when the new courses and certification programs are available.</h1>
          <form className="contact-form" onSubmit={this.onSubmit}>
            <div className="form-group">
              <i className="fa fa-user icon"></i>
              <input type="text" name="name" onChange={this.onChange} placeholder="Name" />
            </div>
            <div className="form-group">
              <i className="fa fa-envelope icon"></i>
              <input type="email" name="email" onChange={this.onChange} placeholder="Email" />
            </div>
            <div className="form-group">
            <PhoneInput
              international
              defaultCountry="US"
              value={this.state.phonenumber}
              onChange={(value) => this.setState({phonenumber: value})}
              required
            />
                        </div>
            <button className="register-button" disable={this.state.registerSuccess ? true : false} type="submit">Submit</button>
          </form>
          {this.state.nameEmpty && <NameEmpty />}
							{this.state.emailEmpty && <EmailEmpty />}
							{this.state.registerFailed && <RegisterFailed />}
							{this.state.registerSuccess && <RegisterSuccess />}
                            {this.state.accountExists && <AccountExists />}
        </main>
        <Footer />
      </div>
    );
  }
}

export default Register;
